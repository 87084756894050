import {
  PortalLayoutMode,
  PortalLayoutSize,
  PortalSideMenuBreakpoint,
} from '@vs/types';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import styles from './portal-layout.module.css';

export type PortalLayoutProps = {
  enableHeaderSpace?: boolean;
  navigateBtn?: React.ReactNode;
  sidebar?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  openDrawer?: boolean;
  onDrawerClickAway?: () => void;
  sidebarHiddenFrom?: PortalSideMenuBreakpoint;
  mode?: PortalLayoutMode;
  size?: PortalLayoutSize;
};

export function PortalLayout({
  enableHeaderSpace = true,
  navigateBtn = null,
  sidebar = null,
  content = null,
  footer = null,
  openDrawer = false,
  onDrawerClickAway = () => {},
  sidebarHiddenFrom = 'md',
  mode = 'app',
  size = 'sm',
}: PortalLayoutProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState(false);
  const hasSidebar = Boolean(sidebar);
  const hasNavigateBtn = Boolean(navigateBtn);

  useEffect(() => {
    if (hasSidebar && containerRef.current !== null) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target === containerRef.current) {
            setScrollable(
              containerRef.current.scrollHeight > window.innerHeight
            );
          }
        }
      });
      observer.observe(containerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [hasSidebar]);

  return (
    <div
      className={classNames(
        'relative box-border flex h-px min-h-screen flex-col',
        { 'pt-14 md:pt-16': enableHeaderSpace }
      )}
    >
      <BackDrop show={openDrawer} onClick={onDrawerClickAway} />
      <div
        className={classNames('flex grow', {
          'bg-vs-base-100': mode === 'app',
          'bg-vs-base-300': mode === 'doc',
          'justify-center': !hasSidebar || mode === 'doc',
        })}
        ref={containerRef}
      >
        {hasSidebar && (
          <div
            className={classNames(
              styles['sidebar-container'],
              'fixed left-0 top-0 z-40 md:z-30 w-full max-w-[20rem] transition-all rtl:right-0 lg:w-60 md:top-16',
              {
                'md:sticky md:translate-x-0 md:rtl:translate-x-0 md:w-18':
                sidebarHiddenFrom === 'md',
                'lg:translate-x-0 lg:rtl:translate-x-0 lg:sticky':
                sidebarHiddenFrom === 'lg',
                [styles['sidebar-container-scrollable']]: scrollable,
                '-translate-x-80 rtl:translate-x-full': !openDrawer,
              }
            )}
          >
            <div
              className={classNames(
                'pt-22 h-full overflow-auto pb-8 pe-6 transition-all md:pt-8 lg:pb-14 lg:pe-0 relative',
                {
                  'bg-vs-base-100': mode === 'app',
                  'bg-vs-base-300': mode === 'doc',
                  'md:w-60': openDrawer,
                  'md:w-18 lg:w-full md:pe-0': !openDrawer,
                }
              )}
            >
              {hasNavigateBtn && !openDrawer && (
                <div className="absolute top-12 lg:left-auto lg:right-1 md:block md:left-0 md:right-0 md:m-auto md:w-6 lg:w-full">
                  {navigateBtn}
                </div>
              )}
              {sidebar}
            </div>
          </div>
        )}
        <div
          className={classNames(
            'grow px-6 pb-8 pt-8 lg:pb-14 lg:pe-8 w-0',
            {
              'lg:ps-10': hasSidebar,
            },
            getLayoutSizeClass(size)
          )}
        >
          {hasNavigateBtn && (
            <div className="flex md:hidden">{navigateBtn}</div>
          )}
          {content}
        </div>
      </div>
      <div className="h-14 w-full shrink-0 grow-0 md:h-16 lg:h-20">
        {footer}
      </div>
    </div>
  );
}

export default PortalLayout;

export function getLayoutSizeClass(size: PortalLayoutSize) {
  return classNames({
    'md:max-w-[720px]': size === 'xs',
    'md:max-w-[840px]': size === 'sm',
    'md:max-w-[960px]': size === 'md',
    'md:max-w-[1280px]': size === 'lg',
    'md:max-w-[1440px]': size === 'xl',
    'md:max-w-full': size === 'full',
  });
}

function BackDrop({
  show = false,
  onClick = () => {},
}: {
  show?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'bg-vs-neut-1000 fixed bottom-0 left-0 right-0 top-0 z-20 h-full w-full transition-opacity lg:pointer-events-none lg:opacity-0',
        {
          'pointer-events-none opacity-0': !show,
          'pointer-events-auto opacity-40': show,
        }
      )}
    />
  );
}
